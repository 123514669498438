// deneb_service
.deneb_service{
	position: relative;
	.grid_item{
		max-width: 370px;
		margin: auto;
		padding: 50px 45px 30px;
		text-align: center;
		border-radius: 8px;
		@include transition(.5s);
		border: 2px dashed $white;
		&:hover{
			border-color: $theme_color;
			box-shadow: 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.15);
		}
		.icon{
			margin-bottom: 30px;
		}
		.info{
			h4{
				@media #{$lg}{
					font-size: 18px;
				}
				margin-bottom: 15px;
				font-weight: 600;
				a{
					color: $title;
					font-weight: 600;
				}
			}
			p{
				margin-bottom: 20px;
			}
			.link_btn{
				font-weight: 500;
				color: $title;
				text-transform: uppercase;
			}
		}
	}
}
.service_v1{
	padding-top: 125px;
	padding-bottom: 90px;
}
.service_wrapper{
	.offer-box {
		margin-bottom:200px;
		&:last-child {
			margin-bottom: 50px;
		}
	}
	.deneb_img_box{
		background-color: #fff;
		position: relative;
		z-index: 1;
		img{
			@media #{$lg}{
				width: 100%;
			}
			@media #{$xs}{
				width: 100%;
			}
			border-radius: 8px;
			max-width: 540px;
			box-shadow: 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.3);
		}
		&:after{
			@media #{$xs}{
				display: none;
			}
			position: absolute;
			right: 0;
			top: -30px;
			content: '';
			width: 94%;
			height: 397px;
			z-index: -1;
			border-radius: 8px;
			background-image: -moz-linear-gradient( 0deg, rgb(0,185,255) 0%, rgb(2,155,212) 100%);
			background-image: -webkit-linear-gradient( 0deg, rgb(0,185,255) 0%, rgb(2,155,212) 100%);
			background-image: -ms-linear-gradient( 0deg, rgb(0,185,255) 0%, rgb(2,155,212) 100%);
		}
	}
	.deneb_content_box{
		@media #{$md}{
			margin-bottom: 60px;
		}
		h2{
			position: relative;
			margin-bottom: 30px;
		}
		p{
			margin-bottom: 20px;
		}
		h2{
			position: relative;
			&:after{
			    position: absolute;
			    content: '';
			    left: -30px;
			    top: -20px;
			    background: url(../images/shape/title.png) no-repeat;
			    display: inline-block;
			    width: 96px;
			    height: 67px;
			    z-index: -1;
			}
		}
	}
}
.service_feature{
	.section_title{
		h2{
			position: relative;
			&:after{
			    left: 70px;
			}
		}
	}
}
// deneb_feature
.service_feature{
	padding-bottom: 100px;
	.feature_wrapper{
		.single_feature{
			.deneb_img_box{
				@media #{$md}{
					margin-bottom: 60px;
				}
			}
			.deneb_content_box{
				max-width: 548px;
				.icon{
					display: inline-block;
					width: 100px;
					height: 100px;
					line-height: 100px;
					text-align: center;
					border-radius: 50%;
					background-color: $white;
					box-shadow: 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.15);
					margin-bottom: 30px;
				}
				h3{
					position: relative;
					font-size: 30px;
					font-weight: bold;
					margin-bottom: 10px;
				}
			}
			&:nth-child(odd){
				.deneb_content_box{
					@media #{$md}{
						margin-bottom: 60px;
					}
					text-align: right;
				}
			}
		}
	}
}
// service_page
.service_page{
	.deneb_pricing{
		padding-bottom: 105px;
	}
}
.service_v1{
	.section_title{
		h2{
			position: relative;
			&:after{
				left: 120px;
			}
		}
	}
}

// Service Three
.service_v3 .grid_item{
	padding: 40px;
	text-align: left;
	box-shadow: 5px 8.66px 22px 0px rgba(0, 0, 0, 0.15);
}

.service-three{
	h6{
		text-transform: uppercase;
		color: #feb000;
	}
	h3{
		font-size: 36px;
	}
	p{
		font-size: 14px;
	}
}
